<template>
  <header class="header-component">
    <div class="container-padding">
      <div class="header-component__inner">
        <router-link :to="{ name: 'home' }" class="header-component__logo">
          <img src="/static/svg/logo.svg" alt="логотип" />
          <span>ГБУ Республика Дагестан <br />“Дагестанский государственный театр оперы и балета”</span>
        </router-link>
        <nav class="header-component__nav">
          <ul class="header-component__links">
            <li v-for="(link, i) in links" :key="i" class="header-component__link">
              <router-link :to="link.route">
                <span>{{ link.title }}</span>
                <IconComponent name="arrow-down-fill" v-if="link.subLinks && link.subLinks.length" />
              </router-link>
              <div v-if="link.subLinks && link.subLinks.length" class="header-component__subLinks">
                <div class="header-component__subLinks-inner">
                  <h2>{{ link.title }}</h2>
                  <ul>
                    <li v-for="(subLink, i) in link.subLinks" :key="i">
                      <router-link :to="subLink.route">
                        <span class="header-component__subLink-title">{{ subLink.title }}</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
          <div class="header-component__buttons">
            <a class="header-component__bvi bvi-open">
              <IconComponent name="glass" />
            </a>
            <a href="#afisha/014311f843f004f2d9d82b76b93b245ddced0cda24f729" class="btn btn--md btn--main">
              Купить билет
            </a>
          </div>
        </nav>
        <a @click="openMobileMenu" class="header-component__burger">
          <IconComponent name="burger" />
        </a>
      </div>
    </div>
  </header>
</template>

<script>
import IconComponent from "components/IconComponent.vue";

export default {
  name: "HeaderComponent",
  data() {
    return {
      links: [
        {
          title: "Афиша",
          route: { name: "posters" },
        },
        {
          title: "Артисты",
          route: { name: "" },
          subLinks: [],
        },
        {
          title: "Репертуар",
          route: { name: "" },
          subLinks: [],
        },
        {
          title: "О театре",
          route: { name: "" },
          subLinks: [
            {
              title: "Администрация",
              route: { name: "administration" },
            },
            {
              title: "Художественно-руководящий состав",
              route: { name: "art" },
            },
            {
              title: "История театра",
              route: { name: "history" },
            },
            {
              title: "Миссия театра",
              route: { name: "mission" },
            },
            {
              title: "Галерея театра",
              route: { name: "galleries" },
            },
            // {
            //   title: "Культура против террора",
            //   route: { name: "cultures" },
            // },
            {
              title: "СМИ о нас",
              route: { name: "media" },
            },
          ],
        },
        {
          title: "Культура против террора",
          route: { name: "cultures" },
        },
        {
          title: "Документы",
          route: { name: "documents" },
          subLinks: [
            {
              title: "Противодействие экстремизму",
              route: { name: "countering_extremism" },
            },
            {
              title: "Противодействие коррупции",
              route: { name: "anti_corruption" },
            },
          ],
        },
        {
          title: "Контакты",
          route: { name: "contacts" },
        },
      ],
    };
  },
  created() {
    this.links[1].subLinks = this.categories_persons;
    this.links[2].subLinks = this.categories_repertoire;
  },
  computed: {
    categories_repertoire() {
      return this.$store.state.categories_repertoire
        ? this.$store.state.categories_repertoire.map((item) => ({
            title: item.title,
            route: {
              name: "repertoires",
              query: { category: item.id },
            },
          }))
        : [];
    },
    categories_persons() {
      return this.$store.state.categories_persons
        ? this.$store.state.categories_persons.map((item) => ({
            title: item.title,
            route: {
              name: "persons",
              query: { category: item.id },
            },
          }))
        : [];
    },
  },
  mounted() {
    require(["bvi"], ({ Bvi }) => {
      new Bvi();
    });
  },
  methods: {
    openMobileMenu() {
      document.body.classList.add("no-scroll");
      this.$store.state.showMobileMenu = true;
    },
  },
  components: {
    IconComponent,
  },
};
</script>

<style lang="stylus">
.header-component {
  position relative
  background var(--gray)
  +below(1400px) {
    padding 10px 0
  }

  & .container-padding {
    position static
  }

  &__inner {
    display flex
    align-items center
    gap 40px
    +below(1400px) {
      justify-content space-between
      //gap 10px
    }
  }

  &__logo {
    display flex
    align-items center
    gap 15px

    img {
      width 55px
      height 55px
      +below(550px) {
        display none
      }
    }

    span {
      font-weight 700
      line-height 1.125rem
      display none
      +below(1400px) {
        display flex
      }
      +below(480px) {
        font-size .875rem
      }
      +below(350px) {
        font-size .75rem
        line-height .875rem
      }
    }
  }

  &__nav {
    width 100%
    display flex
    align-items center
    justify-content space-between
    +below(1400px) {
      display none
    }
  }

  &__links {
    display flex
    align-items center
    gap 50px
  }

  &__link {
    padding 25px 0

    a {
      display flex
      align-items center
      gap 5px
    }

    .icon svg {
      max-width 13px
      max-height 13px
    }

    &:hover {
      .icon svg {
        transform scaleY(-1)
      }
      .header-component__subLinks {
        opacity 1
        visibility visible
      }
    }
  }

  &__subLinks {
    opacity 0
    visibility hidden
    transition var(--transition)
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
    clip-path: inset(0px -15px -35px -15px);
    display flex
    padding 10px 30px
    border-top 3px solid var(--gray)
    z-index 5
    background var(--white)
    width 100%
    absolute left bottom right
    transform translateY(100%)
  }

  &__subLinks-inner {
    display flex
    width var(--main-width)
    margin 0 auto
    gap 300px

    h2 {
      min-width 200px
      margin 0
    }

    ul {
      display grid
      width 100%
      grid-template-columns 1fr 1fr 1fr

      li {
        padding 0 15px
      }
    }
  }

  &__subLink-title {
    font-size .875rem
    line-height 26px
    font-weight 600
    +below(650px) {
      font-size .75rem
      line-height 22px
    }
  }

  &__buttons {
    display flex
    align-items center
    gap 15px
  }

  &__bvi {
    display flex
    align-items center
    justify-content center

    figure {
      max-width 26px
      max-height: 26px

      svg {
        width 26px
        height 26px

        path {
          fill var(--main)
        }
      }
    }
  }

  &__burger {
    display none
    +below(1400px) {
      display flex
    }

    .icon svg {
      //width 30px
      height 14px
      max-width 30px
      max-height 13px
    }
  }
}
</style>
