export default {
  namespaced: true,
  state: () => ({
    galleries_item: null,
    galleries: {
      data: [],
      paginatorInfo: {
        total: 0,
      },
    },
  }),
  actions: {
    save({ state, rootState }, data) {
      if (data) {
        Object.keys(data).forEach((key) => {
          state[key] = data[key];
        });
      }
      if (data.contactinfo) {
        rootState.contactinfo = data.contactinfo;
      }
      if (data.categories_persons) {
        rootState.categories_persons = data.categories_persons;
      }
      if (data.categories_repertoire) {
        rootState.categories_repertoire = data.categories_repertoire;
      }
    },
  },
};
