<template>
  <div id="app">
    <HeaderComponent />
    <Transition mode="out-in" name="page">
      <RouterView :key="$route.fullPath" />
    </Transition>
    <FooterComponent />
    <MobileMenu v-if="$store.state.showMobileMenu" />
    <ModalsComponent />
    <CookiesComponent />
  </div>
</template>

<script>
import CookiesComponent from "components/CookiesComponent.vue";
import HeaderComponent from "components/HeaderComponent.vue";
import FooterComponent from "components/FooterComponent.vue";
import ModalsComponent from "components/modals/index.vue";
import MobileMenu from "components/menus/MobileMenu.vue";

export default {
  name: "App",
  metaInfo() {
    return {
      titleTemplate: `%s | Государственный театр оперы и балета`,
    };
  },
  components: {
    MobileMenu,
    ModalsComponent,
    FooterComponent,
    HeaderComponent,
    CookiesComponent,
  },
};
</script>

<style lang="stylus">
@import 'styles/style.styl';
</style>
