<template>
  <footer class="footer-component">
    <div class="container-padding">
      <div class="footer-component__inner">
        <div class="footer-component__left">
          <div class="footer-component__left-header">
            <router-link :to="{ name: 'home' }">
              <img src="/static/svg/logo.svg" alt="логотип" class="footer-component__logo" />
            </router-link>
            <router-link :to="{ name: 'home' }">
              <span class="footer-component__title">
                ГБУ Республика Дагестан “Дагестанский государственный театр оперы и балета”
              </span>
            </router-link>
          </div>
          <!--          <a href="https://operadag.ru" target="_blank" class="footer-component__old">Старый сайт</a>-->
          <div class="footer-component__left-bottom">
            <span class="footer-component__copyright">
              © {{ year }}. ГБУ Республика Дагестан “Дагестанский государственный театр оперы и балета”
            </span>
            <span class="footer-component__developed">
              Разработано:
              <a href="https://comrades.dev/" target="_blank">Comrades Devs</a>
            </span>
          </div>
        </div>
        <div class="footer-component__right">
          <div class="footer-component__right-header">
            <div class="footer-component__menu">
              <span class="footer-component__menu-title">О театре</span>
              <ul class="footer-component__menu-links">
                <li>
                  <router-link :to="{ name: 'administration' }">Администрация</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'art' }">Художественно руководящий состав</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'anti_corruption' }">Противодействие коррупции</router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'countering_extremism' }">
                    Противодействие экстремизму
                  </router-link>
                </li>
                <li>
                  <router-link :to="{ name: 'cultures' }">Культура против террора</router-link>
                </li>
              </ul>
            </div>
            <div class="footer-component__menu">
              <span class="footer-component__menu-title">Контакты</span>
              <div class="footer-component__menu-links">
                <a v-if="info && info.kassa" :href="`tel:${info.kassa}`">Касса: {{ info.kassa }}</a>
                <a v-if="info && info.administrator" :href="`tel:${info.administrator}`">
                  Администраторы: {{ info.administrator }}
                </a>
                <a v-if="info && info.accounting" :href="`tel:${info.accounting}`">
                  Бухгалтерия: {{ info.accounting }}
                </a>
                <a v-if="info && info.fax" :href="`tel:${info.fax}`">Факс: {{ info.fax }}</a>
              </div>
            </div>
          </div>
          <div class="footer-component__right-bottom">
            <a v-if="info && info.email" :href="`mailto:${info.email}`">Email: {{ info.email }}</a>
            <span v-if="info && info.address">{{ info.address }}</span>
            <div class="footer-component__schedule">
              <span v-if="info && info.working_mode">{{ info.working_mode }}</span>
              <span v-if="info && info.weekends">{{ info.weekends }}</span>
            </div>
            <div v-if="info && info.socials && info.socials.length" class="footer-component__socials">
              <a v-for="(social, i) in info.socials" :key="i" :href="social.link" target="_blank">
                <figure v-if="social.icon" v-html="social.icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import moment from "moment/moment";

export default {
  name: "FooterComponent",
  data() {
    return {
      year: moment().format("YYYY"),
    };
  },
  computed: {
    info() {
      return this.$store.state.contactinfo;
    },
  },
};
</script>

<style lang="stylus">
.footer-component {
  display flex
  justify-content space-between
  align-items center
  padding 50px 0
  background var(--white)
  +below(1280px) {
    padding 25px 0
  }

  &__inner {
    display grid
    grid-template-columns 4fr 8fr
    grid-gap 30px
    +below(1280px) {
      display flex
      flex-direction column-reverse
      gap 30px
    }
  }

  &__left {
    display grid
    align-content space-between
    grid-gap 50px
    +below(1280px) {
      display flex
      flex-direction column
      grid-gap 15px
      width 100%
    }
  }

  &__left-header {
    display grid
    grid-gap 30px
    +below(1280px) {
      grid-gap 10px
    }
  }

  &__logo {
    max-width 180px
    max-height 180px
    +below(1280px) {
      max-width 100px
      max-height 100px
    }
    +below(500px) {
      max-width 64px
      max-height 64px
    }
  }

  &__title {
    font-weight 700
    font-size 1.5rem
    line-height 37px
    +below(1280px) {
      font-size .875rem
      line-height 1.125rem
    }
  }

  //&__old {
  //  font-weight 500
  //  line-height 26px
  //  text-decoration underline
  //}

  &__left-bottom {
    display grid
    grid-gap 5px
  }

  &__copyright {
    font-weight 500
    font-size .875rem
    line-height 1.375rem
    +below(1280px) {
      font-size .75rem
      line-height .875rem
    }
  }

  &__developed {
    font-size .75rem
    font-weight 400
    line-height 26px
    //+below(1280px) {
    //  font-size .625rem
    //  line-height .875rem
    //}
  }

  &__right {
    display grid
    grid-gap 30px
  }

  &__right-header {
    display grid
    align-items start
    grid-template-columns 1fr 1fr
    grid-gap 30px
    +below(1280px) {
      grid-template-columns 1fr
      width 100%
    }
  }

  &__menu {
    display grid
    align-content start
  }

  &__menu-title {
    font-weight 500
    font-size 1.375rem
    line-height 26px
    padding-bottom 20px
    margin-bottom 20px
    border-bottom 1px solid var(--gray_4)
    +below(1280px) {
      font-size 1.125rem
      line-height 1.375rem
    }
  }

  &__menu-links {
    display grid
    grid-gap 15px
    font-weight 400
    font-size 1rem
    line-height 15px
    +below(1280px) {
      grid-gap 10px
    }

    li {
      display inline-flex

      a {
        text-decoration-line underline
        color var(--black)
        +below(1280px) {
          font-size .875rem
          line-height 1.375rem
        }

        &.router-link-active {
          opacity 0.5
        }
      }
    }
  }

  &__right-bottom {
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 30px
    height fit-content
    +below(1280px) {
      grid-template-columns 1fr
    }
  }

  &__schedule {
    display grid
    gap 5px
  }

  &__socials {
    display flex
    flex-wrap wrap
    align-items flex-start
    gap 5px

    a {
      background var(--gray)
      border-radius 6px
      width 50px
      height 50px
      display flex
      align-items center
      justify-content center

      figure {
        max-width 24px
        max-height: 24px

        svg path {
          fill var(--main)
        }
      }
    }
  }
}
</style>
