import Vue from "vue";
import Vuex from "vuex";

// modules
import home from "./modules/home.js";
import documents from "./modules/documents.js";
import history from "./modules/history.js";
import persons from "./modules/persons.js";
import media from "./modules/media.js";
import galleries from "./modules/galleries.js";
import posters from "./modules/posters.js";
import repertoires from "./modules/repertoires.js";
import cultures from "./modules/cultures.js";

Vue.use(Vuex);
export default function createStore() {
  return new Vuex.Store({
    modules: {
      home,
      documents,
      history,
      persons,
      media,
      galleries,
      posters,
      repertoires,
      cultures,
    },
    state() {
      return {
        _modals: [],
        _types: {},
        contactinfo: null,
        categories_persons: null,
        categories_repertoire: null,
        showMobileMenu: false,
      };
    },
    actions: {
      save({ state, rootState }, data) {
        if (data) {
          Object.keys(data).forEach((key) => {
            state[key] = data[key];
          });
        }
        if (data.contactinfo) {
          rootState.contactinfo = data.contactinfo;
        }
        if (data.categories_persons) {
          rootState.categories_persons = data.categories_persons;
        }
        if (data.categories_repertoire) {
          rootState.categories_repertoire = data.categories_repertoire;
        }
      },
    },
  });
}
